import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import { ParallaxProvider } from 'react-scroll-parallax';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import meta from '~/constants/meta';
import { LargeStencil } from '~/components/.base/headings';
import { DeriveHeaderClasses } from '~/components/Navigation';
import './index.scss';
import {
  Catering,
  FoodImages,
  Franchise,
  Hero,
  HomeSlideshow,
  LocationMap,
  TextWithImage,
  ThreeWay,
} from 'components/HomePage';
import styled, { css } from 'styled-components';
import { size } from '../constants/Breakpoint';
import MailingListPopup from '~/components/JoinMailingList/MailingListPopup';
import BezierCurves from '~/components/.base/BezierCurves';

const HomePageSection = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 1600px;
  overflow-x: hidden;
  @media screen and (max-width: 767px) {
    width: 100%;
    overflow-x: ${props => (props.overflowVisible ? 'visible !important;' : 'hidden;')};
    overflow-y: ${props => (props.overflowVisible ? 'visible;' : 'auto;')};
  }
  position: relative;
`;
const BannerSection = styled(HomePageSection)`
  width: 100%;
  max-width: none;
  overflow: visible;
  padding: ${props => props.pd};
  margin: ${props => props.md};
  background-color: ${props => props.bgColor};
`;
const IndexPage = ({ data, location }) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const sections = [
    'Intro',
    '3 Ways to CML',
    'Maine eats',
    "Our lobster's origins",
    'Cater with us',
    'Mobile app',
    'Own a franchise',
  ];
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeLocation, setActiveLocation] = useState(0);
  const [activeSlideIndex, setActiveSlideIndex] = useState(1);
  const isMobile = useMediaQuery({ query: `(max-width: ${size.lg - 1}px)` });

  const openModal = location => {
    setActiveLocation(location);
    setModalIsOpen(true);
  };

  useEffect(() => {
    document.querySelectorAll('.bezierCurvesSection .bezierCurvesButton').forEach((item, i) => {
      item.addEventListener('click', e => {
        e.preventDefault();
        const scrollToElm = document.querySelector(e.currentTarget.getAttribute('href'));
        const tl = gsap.timeline();
        tl.to(window, {
          duration: 0.5,
          scrollTo: {
            y: scrollToElm,
            offsetY: 200,
            autoKill: false,
          },
        });
        scrollToElm.focus({ preventScroll: true });
      });
    });
    const fadeIn = i => {
      let tl = gsap.timeline();
      tl
        // Active Dot
        .to(
          document.querySelector(
            `.bezierCurvesSection .bezierCurvesButtonWrapper:nth-child(${
              i + 1
            }) .bezierCurvesButton__iconWrapper`
          ),
          {
            duration: 0.2,
            borderColor: i < 1 ? activeSlideIndex > 1 ? '#ffffff' : '#490909' : '#d4212c',
          }
        )
        .to(
          document.querySelector(
            `.bezierCurvesSection .bezierCurvesButtonWrapper:nth-child(${
              i + 1
            }) .bezierCurvesButton__iconDot`
          ),
          {
            duration: 0.2,
            backgroundColor: i < 1 ? activeSlideIndex > 1 ? '#ffffff' : '#490909' : '#d4212c',
          },
          0.02
        )
        .to(
          document.querySelector(
            `.bezierCurvesSection .bezierCurvesButtonWrapper:nth-child(${
              i + 1
            }) .bezierCurvesButton__iconInner`
          ),
          {
            duration: 0.2,
            borderColor: 'transparent',
          },
          0.02
        )
        .to(
          document.querySelector(
            `.bezierCurvesSection .bezierCurvesButtonWrapper:nth-child(${
              i + 1
            }) .bezierCurvesButton__title`
          ),
          {
            duration: 0.2,
            opacity: 1,
            pointerEvents: 'auto',
            color: i < 1 ? activeSlideIndex > 1 ? '#ffffff' : '#490909' : '#d4212c',
          },
          0.02
        )
        .set(
          document.querySelector(
            `.bezierCurvesSection .bezierCurvesButtonWrapper:nth-child(${
              i + 1
            }) .bezierCurvesButton__title`
          ),
          {
            fontSize: '12px',
            width: 'auto',
            paddingLeft: '10px',
            color: i < 1 ? activeSlideIndex > 1 ? '#ffffff' : '#490909' : '#d4212c',
          }
        );
    };
    const fadeOut = i => {
      let tl = gsap.timeline();
      tl
        // Inactive Dot
        .to(
          document.querySelector(
            `.bezierCurvesSection .bezierCurvesButtonWrapper:nth-child(${
              i + 1
            }) .bezierCurvesButton__iconWrapper`
          ),
          {
            duration: 0.2,
            borderColor: 'transparent',
          },
          0
        )
        .to(
          document.querySelector(
            `.bezierCurvesSection .bezierCurvesButtonWrapper:nth-child(${
              i + 1
            }) .bezierCurvesButton__iconDot`
          ),
          {
            duration: 0.2,
            backgroundColor: '#BECACC',
          },
          0.02
        )
        .to(
          document.querySelector(
            `.bezierCurvesSection .bezierCurvesButtonWrapper:nth-child(${
              i + 1
            }) .bezierCurvesButton__iconInner`
          ),
          {
            duration: 0.2,
            borderColor: '#8B9A9C',
          },
          0.02
        )
        .to(
          document.querySelector(
            `.bezierCurvesSection .bezierCurvesButtonWrapper:nth-child(${
              i + 1
            }) .bezierCurvesButton__title`
          ),
          {
            duration: 0.2,
            opacity: 0,
            pointerEvents: 'none',
          },
          0.02
        )
        .set(
          document.querySelector(
            `.bezierCurvesSection .bezierCurvesButtonWrapper:nth-child(${
              i + 1
            }) .bezierCurvesButton__title`
          ),
          {
            fontSize: 0,
            width: 0,
            paddingLeft: 0,
          }
        );
    };
    let stSectionNav = ScrollTrigger.create({
      trigger: document.querySelector('.main'),
      start: 'top top',
      end: 'bottom bottom',
      pin: '.main .bezierCurvesSection',
      invalidateOnRefresh: true,
    });
    let stSections = [];
    document.querySelectorAll('.section').forEach((section, i) => {
      let stSection = ScrollTrigger.create({
        trigger: section,
        start: 'top top+=60%',
        end: 'bottom top+=60%',
        invalidateOnRefresh: true,
        onEnter: () => {
          fadeIn(i);
        },
        onEnterBack: () => {
          fadeIn(i);
        },
        onLeave: () => {
          fadeOut(i);
        },
        onLeaveBack: () => {
          fadeOut(i);
        },
      });
      stSections.push(stSection);
    });
    // Specify how to clean up after this effect:
    return () => {
      // all garbage cleanup all goes in a single useEffect for when component leaves
      if (stSectionNav) {
        stSectionNav.kill();
      }
      if (stSections) {
        stSections.map(stSection => {
          stSection.kill();
          return true;
        });
      }
    };
  }, [activeSlideIndex, data]);

  const metaData = [
    meta.google.siteVerification,
    meta.google.shoppingVerification,
    meta.google.openMoves1,
    meta.google.openMoves2,
    meta.facebook,
  ];

  return (
    <ParallaxProvider>
      <div className="App">
        <MailingListPopup />
        <SEO title="Home" meta={metaData} />
        <Criteo type="homepage" />
        <DeriveHeaderClasses location={location} />
        <div
          className="main"
          css={css`
            position: relative;
          `}
        >
          <BezierCurves dots={sections} />
          <Hero
            homepageImages={data.homepageImages.edges}
            slideData={data.contentfulHomePage.heroSlides}
            homepageData={data.contentfulHomePage}
            onSlideChanged={setActiveSlideIndex}
          />
          <HomePageSection id="homepageWaystoCML" className="section" tabIndex="-1">
            <ThreeWay />
          </HomePageSection>
          <div className="food-images-cont" style={{ position: 'relative' }}>
            <LargeStencil className="lobster-stencil" disabled>Maine Lobster</LargeStencil>
            <BannerSection
              id="homepageMaineeats"
              className="section"
              pd="0"
              md="0"
              style={{ overflow: 'visible' }}
              tabIndex="-1"
            >
              <FoodImages
                homepageImages={data.homepageImages.edges}
                bgImages={data.bgImages.edges}
                homePageData={data.contentfulHomePage}
              />
            </BannerSection>
            {!isMobile && (
              <BannerSection className="section" pd="0" md="0" overflowVisible={true}>
                {/* <Mobile>
                                <BackgroundDiv left="5%" top="-200px" z="-1">
                                    <Image
                                        fluid={getImage(
                                            data.bgImages.edges,
                                            "blurred_smudge"
                                        )}
                                        alt=""
                                    />
                                </BackgroundDiv>
                            </Mobile> */}
                <BannerSection pd="0" md="0" style={{ overflow: 'hidden' }}>
                  <LocationMap
                    homepageImages={data.homepageImages.edges}
                    bgImages={data.bgImages.edges}
                    openModal={openModal}
                    homePageData={data.contentfulHomePage}
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}
                    activeLocation={activeLocation}
                  />
                </BannerSection>
              </BannerSection>
            )}
          </div>
          <HomePageSection
            id="homepageCaterwithus"
            className="section"
            overflowVisible={true}
            style={{ overflow: 'visible' }}
            tabIndex="-1"
          >
            <Catering
              homepageImages={data.homepageImages.edges}
              bgImages={data.bgImages.edges}
              homePageData={data.contentfulHomePage}
            />
          </HomePageSection>
          <BannerSection className="section" pd="0" md="0" overflowVisible={true} tabIndex="-1">
            <TextWithImage
              homepageImages={data.homepageImages.edges}
              homePageData={data.contentfulHomePage}
            />
            {/* <DeliveryApp
              homepageImages={data.homepageImages.edges}
              bgImages={data.bgImages.edges}
              homePageData={data.contentfulHomePage}
              videoLink={data.contentfulHomePage.mobileAppVideoLink}
            /> */}
          </BannerSection>
          <BannerSection className="section" pd="0" md="0">
            <Franchise
              homepageImages={data.homepageImages.edges}
              bgImages={data.bgImages.edges}
              homePageData={data.contentfulHomePage}
            />
          </BannerSection>
        </div>
        {/* <BannerSection>
          <HomePageVideo
            videoLink={data.contentfulHomePage.homePageVideoVimeoLink}
            bgImages={data.bgImages.edges}
          />
        </BannerSection> */}
        <HomeSlideshow
          homepageImages={data.homepageImages.edges}
          homepageData={data.contentfulHomePage}
        />
      </div>
    </ParallaxProvider>
  );
};

export const query = graphql`
  query {
    contentfulHomePage(title: { eq: "Home Page" }) {
      title
      parallaxButtonText
      parallaxButtonLink
      showButtonOnParallaxSlide
      heroSlides {
        title
        slideImage {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        mobileImage {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        buttonText
        buttonLink
        whiteHeaderText
        useOverlay
        overlayOpacity
      }
      foodItemSectionTitle
      foodItems {
        foodImage {
          fluid(maxWidth: 600, quality: 70, sizes: "(max-width: 600px) 40vw, 600px") {
            ...GatsbyContentfulFluid_withWebp
          }
          fixed(width: 170, height: 170) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
        foodTitle
        foodDesc
        buttonText
        buttonLink
      }
      locationSectionTitle
      locationSectionSubtitle
      locations {
        locationName
        locationDesc {
          locationDesc
        }
        locationTitle
        locationInitials
        locationLatitude
        locationLongitude
        locationImage {
          fluid(maxWidth: 1600, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        usesVideo
        videoLink
      }
      cateringSectionTitle
      cateringSectionSubtitle
      cateringSectionDesc {
        cateringSectionDesc
      }
      cateringSectionButtonText
      cateringSectionMobileImage {
        fluid(maxWidth: 800, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      cateringSectionDesktopImage {
        fluid(maxWidth: 1600, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      textWithImageSectionTitle
      textWithImageSectionAppStoreLink
      textWithImageSectionGooglePlayLink
      textWithImageSectionTextWithImageBlock {
        title
        heading
        description {
          description
        }
        mobilePreview {
          description
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      mobileAppSectionTitle
      mobileAppSectionSubtitle
      mobileAppSectionButtonText
      franchiseSectionTitle
      franchiseSectionSubtitle
      franchiseSectionDesc {
        franchiseSectionDesc
      }
      franchiseSectionButtonText
      franchiseSectionBannerImage {
        fluid(maxWidth: 1600, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      franchiseFoodImage {
        fluid(maxWidth: 400, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      homePageVideoVimeoLink
      mobileAppVideoLink
      mobileAppFile {
        title
        file {
          contentType
          url
        }
      }
      homepageSlideshowSectionTitle
      homepageSlideshowSectionBlocks {
        mediaMobile {
          description
          fluid(maxWidth: 1800, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        media {
          description
          fluid(maxWidth: 1800, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        quotation
        author
      }
    }
    homepageImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/images/homepage/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1600, quality: 80) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
            original {
              width
            }
          }
        }
      }
    }
    bgImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/images/background/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 915, quality: 80) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
